//myteam
.myTeamMain {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media screen and (max-width:1352px){
      grid-template-columns: repeat(2, 1fr);

  }
}

.titleColor {
  color: #77777A !important;
}

.myTeamDisplayName {
  font-size: 16px;
  line-height: 18x;
}

.myTeamTitle,
.myTeamEmailId {
  font-size: 12px;
  color: #77777A;
  line-height: 18px;
}

.my-team-card{
  position: relative;
}

.my-team-kebab-menu{
  position: absolute;
  top: 30px;
  right: 20px;
}

.team-profile-image {
  max-width: 96px;
}

.team-btn-link-container {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  .team-btn-link {
    background: transparent;
    border: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.team-kebab-menu {
  top:15px;
  right:40px;
  z-index: 9999;
}


@media screen and (max-width: 450px) {
  #MobileTeam {
    width: 100%;
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
    margin: auto;
    gap: 10px !important;
  }

  .MobileTeamEach {
    margin: 5px !important;
    font-size: 12px !important;
  }

  .myTeamMobileProfile {
    width: 56px !important;
    margin-top: 10px;
  }

  .team-profile-image {
    max-width: 96px;
  }
}

// @media screen and (min-width:451px) and (max-width:1366px){
//  #desktop{
   
//         display: grid;
//         grid-template-columns: repeat(2, 1fr);
      
//  }  
// }