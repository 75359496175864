@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");
@import url("../assets/styles/colors.scss");
.App {
  text-align: center;
  font-family: "Manrope", sans-serif;
  background-color: var(--silverColor);
}

.header-container {
  width: 84%;
}

.sidebar-container {
  width: 16%;
}

.LoginText{
  font-family: "Manrope", sans-serif;
  font-size: 48px;
  line-height: 63.98px;
  font-weight: 700;
  color: var(--dimGray);
}

.loginBtn{
  background-color:var(--darkBlue) !important;
  color: var(--white) !important;
}