.eachRow {
  width: 600px !important;
  display: flex;
  justify-content: left;
  gap: 100px;
}

.eachRowValue {
  text-align: left !important;
}
.profileImage{
  width: 151px;
  height: 151px;
  border-radius: 50%;
}
@media screen and (max-width: 450px){

#mobileProfile{
  display: flex;
  flex-direction: column;
}
.profileImage{
  width: 64px;
  height: 64px;
}

  // .profileImage {
  //   width: 80px;
  // }

  .mobileProfileTop {
    display: flex;
    gap: 10px;
  }

  .userName {
    font-size: 18px;
  }

  .jobTitle {
    font-size: 15px;
  }

  .profileBottomMain {
    margin-top: 5px;
  }

  .profileText {
    border: 2px solid white;
  }

  .bottom {
    font-size: 13px;
    width: 320px !important;
    margin-top: 1rem;
  }

  .eachRow {
    width: 360px !important;
    gap: 30px;
  }
// .eachRow{
//   width: 90% !important;
//   gap: 10px !important;
// }

.eachRow p:nth-child(1){
  width: 40% !important;
}

.eachRow p:nth-child(2){
  width: 50% !important;
}
 .emailWrap {
    word-wrap:break-word;
  }
}