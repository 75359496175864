.eachRow {
  width: 100%;
  gap: 20px;
}

.left {
  width: 30%;
  color: var(--modalGrayColor);
}

.right {
  width: 60%;
  font-size: 16px;
}

.status {
  height: 2rem;
  margin-top: 2rem;
}

.custom-input-text-dropdown {
  width: 300px;
}

@media screen and (max-width: 480px) {
  .leaveRequestPendingModal {
    width: 95% !important;
  }
  .leaveRequestTable tr td {
    margin: -12px 0px -12px 0px !important;
  }
  .leaveRequestTable tr td:nth-child(7) {
    margin: 0px 0px 10px 0px !important;
  }
  .leaveRequestTable tr td:nth-child(8) {
    margin-bottom: 0px !important;
  }
  .leaveRequestViewBtn {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .p-input-icon-left {
    width: 100% !important;
  }

  .custom-input-text-dropdown {
    width: 100% !important;
  }
}

.searchbar {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
}

.p-input-icon-left > i {
  top: 45% !important;
}
