.running-payroll-details{
    background-color: #F8FAFC;
}

.running-payroll-bg{
    background-color: var(--lightGray);
}

.payroll-left-component {
    border-right: 2px dotted #d6d6d6;
}

.running-payroll-components{
    background-color: #FFFFFF;
}

 #Individual-payslip-header .p-card .p-card-content{
    padding: 0 !important;
 }



