@import url("./assets//styles/responsive.scss");
@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");
@import url("./assets/styles/colors.scss");

html,
body {
  margin: 0%;
  height: 100%;
}

body {
  .p-tooltip {
    .p-tooltip-text {
      background: var(--white);
      color: var(--black);
    }
  }
  .p-tooltip-bottom {
    .p-tooltip-arrow {
      border-bottom-color: var(--white) !important;
    }
  }
}

.active:hover {
  background-color: var(--sidebarHoverColor);
  border-radius: 10px;
}

.profile-active:hover {
  background-color: var(--lightGray);
  padding: 7px 5px;
}
.activeLink {
  background-color: var(--sidebarActiveColor);
  border-radius: 10px;
}

.bg-clr51 {
  background-color: var(--darkblue);
}

.txt-clr00 {
  color: var(--black);
}

.txt-clr64 {
  color: var(--dimGray);
}

.bg-clr2B {
  background: var(--sidebarActiveColor);
}

.bg-clr43 {
  background: var(--sidebarBgColor);
}
.w {
  width: 20%;
}

.fnt-56 {
  font-size: 56px;
}

.text-style {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  color: var(--white);
}

#root {
  height: 100%;
}

.leavePeriodError {
  margin-left: 17%;
  margin-bottom: 1rem;
}

.calendar {
  width: 100%;
  margin: auto;
  background-color: var(--lightGray);
  padding: 2rem 3rem 3rem 3rem;
}

.fc-day-sun,
.fc-day-sat {
  background-color: var(--gray);
}

.fc-day-today {
  background-color: var(--lightViolet) !important;
}

.fc-icon-chevron-left,
.fc-icon-chevron-right {
  display: flex !important;
  align-items: center !important;
  padding: 12px 0px;
}
.header-container {
  font-family: "Manrope";
}

.fc-today-button {
  visibility: hidden;
  position: relative;
}

.fc-today-button:after {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  content: "Today";
  background-color: var(--lightViolet);
  padding: 7px;
  border-radius: 5px;
}

.infoIcon {
  width: 30.19px;
  display: block;
  margin-left: auto;
}

//Hamburger responsiveness

.MobileHamburger {
  display: none;
}

.DesktopView1 {
  display: block;
}
//Footer
.FooterText {
  color: #13928a;
}

.TextColor {
  color: white !important;
}

.footer {
  background-color: var(--sidebarActiveColor);
  position: static;
  bottom: 0;
  width: 100%;
}

.tmsContainer {
  min-height: calc(100vh - 118px);
}

//Header
.headerProfileImage {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.fc-event-main {
  border: none !important;
  border-width: 0 !important;
}
.p-progress-spinner-circle {
  stroke: white !important;
}

.custom-progress-spinner-color .p-progress-spinner-svg circle {
  stroke: #000000 !important;
}

.container-height {
  min-height: 85vh;
}

//mobile
@media screen and (max-width: 450px) {
  .MobileHamburger {
    display: block;
  }
  .mobileHamburgerIcon,
  .closeBtnIcon {
    height: 24px;
  }
  .MobileLogoImg {
    height: 24px;
    width: 200px;
  }
  #DesktopView {
    display: none !important;
  }

  #leaveRequest .p-datatable-tbody > tr {
    margin-bottom: 20px !important;
    display: flex;
    flex-direction: column;
  }
  .modalTextArea {
    width: 60%;
  }

  .MobileLogoCloudprismImg {
    height: 30px;
    width: 80px;
  }
}
