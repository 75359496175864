//view desktop
.MobileViewRight{
  width: 200px;
}
#MobileStatusRight{
  width: 60px;
  height: 30px;
  margin-top: 0.5rem;
}
.MobileStatus{
  gap: 7rem;
}

#addedSubtractedModal,#hideSubtracted,#hideComment {
  display: none;
}
.leaveStatus{
  display: none;
}
@media screen and (max-width: 450px){
    
    //card
  #MobileCard{
    width: 100%;
    display: grid !important;
    grid-template-columns: repeat(2,1fr) !important;
  }
  #MobileCardEach{
    width: 80% !important;
  }
 .fontSizeMobile{
  font-size: 40px !important;
 }
    //modal myleave
  .MobileDialog{
    width: 90% !important;
  }
 .MobileForm{
   margin-top: 2rem;
 }
 #MobileLeaveTypeText,#MobileLeaveDurationText{
   width: 100px !important;
   display: flex;
 }
 #MobileLeaveType{
   display: flex;
   gap: 50px;
 }
 #MobileLeaveDurationRight{
   display: flex;
   gap: 10px;
   flex-direction: column;
   justify-content: space-between;
   text-align: left;
   align-items: flex-start; }

.MobileLeaveDuration{
  display: flex !important;
  gap: 3rem;
  justify-content: space-between !important;
}

.MobileMultipleCalendar{
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 0px !important;
  
}
.MobileCalendar{
  display: flex;
  gap: 50px;
}
.MobileSingleCalendar{
   width: 90%;
}

.MobileReason{
  display: flex;
  flex-direction: column;
}
#MobileReasonText{
  display: flex;
  width: 150px !important;
  margin-bottom: 10px;
}

.MobileReasonTextArea{
  width: 500px !important;
}
#MobileButtonMain{
  width: 95%;
  display: flex !important;
  justify-content: space-between !important;
}
.MobileButtonLeft,.MobileButtonRight{
  width: 130px !important;
  padding: 5px 0px 5px 0px;
  display: flex;
  justify-content: center;
}
.MobileButtonRight{
color: white;
}
.MobileLeaveModal{
  margin: auto !important;
  margin-left: 1rem !important;

}

//myLeave view modal

#MobileViewModal{
  width: 90% !important;
}

.MobileViewLeft{
  width: 500px;
}

.MobileViewRight{
  width: 600px;
}
#MobileStatusRight{
  width: 60px;
  height: 30px;
  margin-top: 0.5rem;
}
.MobileStatus{
  gap: 7rem;
}
.MobileViewButton{
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

#MobileViewButtonLeft{
  border: 1px solid !important;
  width: 130px;
  justify-content: center;
}
.MobileViewButtonRight{
    width: 130px;

}

#MobileWithdraw{
  width: 90% !important;
}
.MobileAcceptedButton{
  margin-top: 10px !important;
  margin-right: 25px !important;
}

.MobileWithdrawTop{
  margin-top: 1rem;
}

#myLeaveMain .p-datatable-tbody>tr{
  margin-bottom: 20px !important;
  display: flex;
  flex-direction: column;
}

#myLeaveMain .p-datatable-tbody td{
 height: fit-content !important;
 padding: 10px !important;
}

//my leave table

.myLeaveTable tr td{
  margin: -10px 0px -10px 0px !important;
}
.myLeaveTable tr td:nth-child(6){
   margin: 0px 0px 0px 0px !important;
}
.myLeaveTable tr td:nth-child(7){
margin-bottom: 0px  !important;
}
.myLeaveViewBtn{
  padding-top: 5px !important;
  padding-bottom: 7px !important;
}
.leaveStatus{
  width: 92%;
  margin: auto;
  margin-bottom: 1rem;
  display: block;
}
.leaveStatusCard{
  font-weight: bold !important;
}
}