.top {
  margin: auto;
  margin-bottom: 1.2rem;
  margin-top: 1.2rem;
  padding: 0.7rem;
}

.currentyear {
  margin-left: 6.1rem;
}

.currentyearcard {
  margin-right: 2.5rem;
  padding-left: 2.5rem;
}

.tablecurrent {
  margin-left: -4.5rem;
}

.colLeft {
  padding-left: 3.8rem;
}

.upcomingyear {
  margin-left: 9.2rem;
}

.upcomingcard {
  margin-right: 2.6rem;
  padding-left: 1.2rem;
}

.tablupcoming {
  margin-left: -7.2rem;
}

.main {
  width: 100%;
  margin: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
.p-tabview-nav,
.p-tabview-nav-link {
  background-color: #f8f9fa !important;
}
@media screen and (max-width: 450px) {
  .MobileMainCurrent {
    margin: auto;
  }

  .MobileMain1 {
    width: 100%;
    margin-left: 1rem;
  }

  .MobileMainNext {
    width: 82%;
    margin-left: 10%;
  }
  .MobileMain2 {
    width: 100%;
    margin: auto;
    margin-left: 18%;
  }

  .colLeft {
    padding-left: 5px !important;
    width: 33% !important;
  }

  .colLeftBottom {
    padding-left: 5px !important;
    width: 33% !important;
  }
}

.weekend-row {
  background-color: #a1a1a1 !important;
  color: white !important;
}
