.input-error {
    border: 2px solid red !important;
}

.delete-btn-clr{
    background-color: red !important;
}

.payroll-error-message{
    color: red;
}
