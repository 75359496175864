.loginMain {
  background-color: #2b3351;
}

.kittens {
  display: flex;
  align-items: flex-end;
  margin: 1rem;

  span {
    color: #2b3351;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
  }
}

.kitten-image {
  z-index: 1001;
  position: absolute;
  top: 100px;
  left: 500px;
}

@media screen and (max-width: 480px) {

  .kittens, .kitten-image {
    display: none;
  }

  .MobileButton {
    background-color: white !important;
    color: black !important;
    margin-top: 2rem !important;
  }
  .MobileLoginText {
    width: 170px;
    font-size: 15px;
  }
  .loginMainRight {
    position: relative;
    right: 0;
    margin: auto;
  }

  .mobileBottom {
    position: absolute;
    right: 0;
    margin-right: 10px;
    bottom: 0;
  }
  .hideForMobile{
    display: none;
  }
  .topImg{
    border: 3px solid white;
    margin-bottom: 15rem !important;
  }

  .middle{
    margin-right: 34% !important;
  }
}