:root {
  --gray: #F6F6F6;
  --blue: blue;
  --lightGray:#eff3f8;
  --white:white;
  --sidebarHoverColor:#3a4368;
  --sidebarActiveColor:#2b3351;
  --darkblue:rgba(51, 113, 242, 0.07);
  --black:#000000;
  --dimGray:#646464;
  --sidebarBgColor:rgba(43, 51, 81, 0.8);
  --lightWhite:#f8fafc;
  --darkBlue:#0067b8;
  --silverColor:#c6c6c6;
  --skyblue:#47a2ed;
  --lightViolet:#9b9ef4;
  --darkBlue:#2B3351;
  --modalGrayColor:#77777A;
  --lightGrey:#D6D6D6;
}