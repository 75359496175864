.fc-event-title {
  color: var(--blue);
  font-weight: bolder;
  font-size: 1rem !important;
  font-family: "Manrope" !important;
}

.fc-day-disabled {
  visibility: hidden;
  border: none !important;
}

.punchIn {
  display: flex;
  gap: 7px;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 2rem;
  margin-bottom: -6px;
  margin-left: 2px;
}

.fc-daygrid-day-events {
  border: none;
}

.fc-scrollgrid-sync-table .fc-day {
  height: 9rem;
}

.fc-h-event {
  border: none;
}

.borderForAbsent {
  border: 4px solid #f59e0b;
}

.borderForHalfday {
  border: 4px solid #a15df6;
}

.borderForLate {
  border: 4px solid #a46465;
}

.absentColor {
  color: #f59e0b;
}

.halfDayColor {
  color: #a966ff;
}

.lateColor {
  color: #a66666;
}

.fc-scroller {
  overflow-y: hidden !important;
}

@media screen and (max-width: 450px) {
  .fc-toolbar-title {
    font-size: 18px !important;
  }

  .fc-daygrid-day-events {
    display: none;
  }

  .calendarText {
    display: none !important;
    font-size: 0px !important;
  }

  .fc-button-group {
    width: 50px !important;
  }

  .fc-toolbar-chunk {
    display: flex;
    gap: 18px;
  }

  .fc-scrollgrid-sync-table .fc-day {
    height: 2rem;
  }

  #calMain {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
}

#calMain .p-card .p-card-body {
  padding: 0rem !important;
  margin: 0rem 1rem;
}

.calender-header .p-card .p-component {
  margin-bottom: 1rem;
}

#calMain .calender-header-text {
  font-weight: 600;
  color: #2b3351;
  font-size: 20px;
}

.calender-tooltip {
  width: 375px;
  position: absolute;
  right: -50px;
  top: 0px;
  background-color: #fff;
}

.absent {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #f59e0b;
}

.holiday {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #007536;
}

.leave {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #3b82f6;
}

.leave-taken {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #45b500;
}

.early {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #a66666;
}

.half {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #a15df6;
}

.tooltip-text {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.round-circle {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 0px;
}

.round-circle-absent {
  background-color: #f59e0b;
}

.round-circle-holiday {
  background-color: #007536;
}

.round-circle-leave {
  background-color: #3b82f6;
}

.round-circle-leave-taken {
  background-color: #45b500;
}

.round-circle-early {
  background-color: #a66666;
}

.round-circle-half {
  background-color: #a15df6;
}

.punchin-punchout {
  display: flex;
}
.calendar-font-size {
  font-size: small;
}
