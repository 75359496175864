.individual-employee-details {
  background-color: #fff;
}

.profile-image {
  max-width: 96px;
}

#Individual-payroll-header .p-datatable .p-datatable-thead > tr > th {
  padding: 1rem 7rem;
  color: var(--black);
  line-height: 24px;
  font-weight: 700;
}

#Individual-payroll-header .p-datatable .p-datatable-tbody > tr > td {
  padding: 1rem 7rem;
  color: var(--black);
  font-weight: 600;
}

#Individual-payroll-header .cust-view-btn {
  border-radius: 4px;
  padding: 8px 12px;
  background: var(--sidebarActiveColor) !important;
}

#Individual-payroll-header .cust-view-btn a {
  text-decoration: none;
  color: #fff;
}

.modal-table {
  width: 100%;
  tr {
    td {
      width: 50%;
      padding: 0%;
    }
  }
}

.run-payroll {
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 1300px) {
  .employee-info {
    display: flex;
    justify-content: space-between;
  }
}

.modal-fields {
  width: 242px !important;
}
