#account-card-header{
    .p-card{
        .p-card-body{
            padding:0;
            .p-card-content {
                    padding: 0;
                }
        }

    }
}

.table-container {
    background-color: white;
    height: 100%;
    /* Set the desired height for the table container */
    overflow-y: scroll;
    /* Enable vertical scrolling */
    overflow-x: scroll;
    
}

.scrollable-table {
    width: 100vw;
    /* Ensure the table takes up the full width of its container */
    border-collapse: collapse;
}

.scrollable-table th,
.scrollable-table td {
    width: 10%;
    padding: 8px;
    /* Adjust the padding as needed */
    
    /* Add borders to cells */
}


.scrollable-table th {
    /* Set a background color for header cells */
    font-weight: bold;
}