.Individual-payroll-download .p-card-body {
  padding: 0 !important;
}

.custom-progress-spinner {
  width: 70px !important ;
  height: 23px !important;
}


@media screen and (max-width: 576px) {
  .payroll-card {
    padding-left: 20px !important;
  }
}

@media screen and (min-width: 450px) {
  .mobilePayslipPage {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .desktopPayslipDownload {
    display: none;
  }
}

#mobilePayslipId .download-card {
  box-shadow: 20px #ffffff;
}

#mobilePayslipId .p-card .p-card-content {
  padding: 0 !important;
}

#mobilePayslipId .dark {
  color: black;
  font-weight: 600;
}

#mobilePayslipId .payslip-mobile-button {
  padding: 0px 30px !important;
  height: 40px;
}
