@import url("./colors.scss");

.cust-btn-clr {
  background: var(--sidebarActiveColor) !important;
}

.cust-btn-clr:hover {
  background: var(--sidebarActiveColor) !important;
}

#team-card-header .p-card-body {
  padding-top: 0;
  padding-bottom: 0;
}

.my-team-bg {
  background-color: var(--lightGray);
}

.cust-pro-user-clr {
  background-color: var(--lightWhite);
}

.cust-status-btn {
  border-radius: 25px;
  padding: 5px 10px;
}

.payroll-template-bg {
  background-color: var(--lightGray);
}

#payroll-card-header .p-card-body {
  padding-top: 0;
  padding-bottom: 0;
  color: var(--black);
}

#payroll-buttons .p-card .p-card-content {
  padding: 0rem 0rem;
}

#payroll-buttons .p-button:focus {
  box-shadow: none;
}

#payroll-card-header .p-datatable .p-datatable-thead > tr > th {
  padding: 1rem 4rem;
  color: var(--black);
  line-height: 24px;
  font-weight: 700;
}

#payroll-card-header .p-datatable .p-datatable-tbody > tr > td {
  padding: 1.5rem 4rem;
  color: var(--black);
  font-weight: 600;
}

#payroll-card-header .cust-view-btn {
  border-radius: 4px;
  padding: 8px 12px;
  background: var(--sidebarActiveColor) !important;
}

#payroll-card-header .cust-view-btn a {
  text-decoration: none;
  color: #fff;
}

.payroll-page-bg {
  background-color: var(--lightGray);
}

.payroll-left-component {
  border-right: 2px dotted #d6d6d6;
}

#payroll-page-header .payroll-page-inputBox {
  height: 3rem;
  width: 17rem;
}

#payroll-page-header .p-card-body {
  padding: 0.5rem 2rem;
}

#payroll-page-header .p-card .p-card-content {
  padding: 0.5rem 0rem;
}

#payroll-page-header .p-card-body {
  color: var(--black);
}

#payroll-page-content .p-card-body {
  padding: 0.5rem 2rem;
}

#payroll-page-content .p-card .p-card-content {
  padding: 0.5rem 0rem;
}

#payroll-page-content .p-button:focus {
  border: none;
}

#payroll-component .p-inputtext {
  border: 1px solid #000000;
}

#payroll-component .payroll-outerBox {
  border: 1px solid #e2e8f0;
  margin-right: 6rem;
  margin-bottom: 0.5rem;
  min-width: 250px;
  max-width: 260px;
}

#payroll-component .payroll-add-btn {
  color: #000000;
  background: none;
  border: none;
  text-decoration: underline;
}

#payroll-component .payroll-add-btn:hover {
  border: none;
}

.employee-payroll-bg {
  background-color: var(--lightGray);
}

#employee-payroll-page .p-card-body {
  padding: 0.5rem 1rem;
}

#employee-payroll-page .cust-view-btn {
  border-radius: 4px;
  padding: 8px 12px;
  background: var(--sidebarActiveColor) !important;
}

#employee-payroll-page .cust-view-btn a {
  text-decoration: none;
  color: #fff;
}

.employee-payroll-calender .p-button {
  background-color: #2b3351 !important;
}

.employee-payroll-buttons .p-button .p-button-outlined {
  color: #2b3351 !important;
}

.employee-modal-para {
  color: #000000;
  font-weight: 400;
}

.all-employee-page {
  background-color: var(--lightGray);
}

#allEmployeePayroll .p-card .p-card-content {
  padding: 0;
}

.employeeDetils-back-btn {
  color: #64748b;
  border: 1px solid #647488;
  max-height: 2.5rem;
  padding: 0.4rem 1rem 0.3rem 1rem;
  cursor: pointer;
}

.team-profile-image {
  max-width: 120px;
}

.tab-panel-bg {
  .p-tabview-panels {
    background-color: var(--lightGray) !important;
  }
}
